if ($(".home")[0]) {
    var booker = $('.booker form');
    var button = $('.js-btn-booker');
    var left = button.offset().left;

    $(document).scroll(function() {
        var y = $(this).scrollTop();
        var x = booker.offset().top + booker.outerHeight();
        if (y > x) {
            button.addClass('js-booker-fixed');
            $('.js-booker-fixed').css('left', left);
            button.delay(200).css('opacity', '1');
            button.css('display', 'fixed');
        } else {
            button.removeClass('js-booker-fixed');
            button.css('left', '0px');
            $('.js-booker-fixed').delay(200).css('opacity', '0');
            button.css('display', 'inline-block');
        }
    });
}

$(".btn-scroll").on('click', function(e){
     e.preventDefault();
     $('html,body').animate({ scrollTop: $('.section-visual').prop("scrollHeight")}, 1000);
});


$('.nav-close').on('click', function(){
    $('.navigation').addClass('closed');
});
$('.nav-open').on('click', function(){
    $('.navigation').removeClass('closed');
});